<template>
  <section class="w-full my-3">
    <div class="intro-y flex flex-col md:flex-row items-center">
      <h1 class="text-lg font-bold truncate mr-5">Información por Líneas de Credito</h1>
      <div class="ml-auto flex flex-col md:flex-row items-center text-theme-1 dark:text-theme-10 w-full md:w-auto">
        <Button icon="pi pi-info-circle" class="p-button-rounded p-button-info p-button-text" v-tooltip.left="'Informacion por líneas de crédito'"/>
      </div>
    </div>
  </section>

  <!-- Componente linea de credito -->
  <div v-for="(lineCredit, index) in reportCreditLines" :key="index">
    <item-credit-line
      :creditLineId="lineCredit.id"
      :nameCreditLine="lineCredit.name"
      :totalCancelado="lineCredit.totalCancelado"
      :totalDeuda="lineCredit.totalDeuda"
      :totalCupo="lineCredit.totalCupo"
      :totalDisponible="lineCredit.totalDisponible"
      :totalSaldo="lineCredit.totalSaldo"
      :cantidad-bancos="lineCredit.cantidadBancos"
      :cantidad-creditos="lineCredit.cantidadCreditos"
      :isVisible="lineCredit.isVisible"
      @banksDetails="detailsBanks"
    />
  </div>

</template>
<script>
import { defineAsyncComponent, onMounted, ref } from 'vue'
import listCreditLines from '../../../services/reportCreditLines'

export default {
    name: 'DashboardPrincipal',
    components: {
      itemCreditLine: defineAsyncComponent(() => import('./components/creditLine/index2'))
      // lineCredit: defineAsyncComponent(() => import('./components/creditLine'))
    },
    setup () {
      const reportCreditLines = ref({})
      const viewData = ref(false)
      const isVisible = ref(false)

      /* Obtener el listado */
      const fetchCreditLines = () => {
        return listCreditLines({}).then(({ status, data }) => {
          reportCreditLines.value = data.result.map(v => ({ ...v, isVisible: isVisible.value }))

          if (reportCreditLines.value.length > 0) {
            viewData.value = true
          } else {
            viewData.value = true
          }
        }).catch(err => {
          console.error(err.message)
        })
      }

      /* Detalle de los bancos */
      const detailsBanks = (creditLineId, isVisible) => {
        const arrayTest = reportCreditLines.value.filter((item) => {
          return item.id === creditLineId
        })

        arrayTest[0].isVisible = !isVisible
      }

      onMounted(() => {
        fetchCreditLines()
      })

      return {
        reportCreditLines,
        isVisible,
        viewData,
        detailsBanks
      }
    }
}
</script>
<style lang="scss">

</style>
